import React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { graphql } from 'gatsby';

import { getImagePreview } from 'utils';

import { Paragraphs, Layout, SEO } from 'components';

const EventTemplate = ({
  data: {
    nodeEventPage: {
      field_event_date,
      field_event_time,
      field_timezone,
      title,
      field_title_formatted,
      field_meeting_link,
      field_iframe_link,
      field_metatags,
      path: { alias },
      field_automatic_breadcrumbs,
      relationships: { field_image_header, field_content, field_breadcrumbs },
    },
  },
}) => {
  const headerData = {
    internal: {
      type: 'paragraph__header_paragraph',
    },
    field_event_date,
    field_event_time,
    field_timezone,
    field_title_formatted,
    title,
    field_meeting_link,
    field_iframe_link,
    alias,
  };

  const imageOpenGraphPreview = field_image_header?.localFile?.childImageSharp?.fluid?.src;

  const imageUrl = getImagePreview(imageOpenGraphPreview);

  return (
    <Layout
      headerData={[headerData]}
      nodeTitle={title}
      isDefaultBreadcrumbs={field_automatic_breadcrumbs}
      customBreadcrumbs={field_breadcrumbs}
      type="front event-page"
      showHeaderForm="event"
    >
      <SEO
        title={field_metatags?.title || title}
        description={field_metatags?.description || ''}
        keywords={field_metatags?.keywords || ''}
        robots={field_metatags?.robots}
        currentUrl={alias}
        imageUrl={imageUrl}
      />
      <div>
        {field_content.map((item) => (
          <Paragraphs key={uid(item)} data={item} />
        ))}
      </div>
    </Layout>
  );
};

EventTemplate.propTypes = {
  data: PropTypes.shape({
    nodeEventPage: PropTypes.shape({
      field_event_date: PropTypes.string,
      field_event_time: PropTypes.string,
      field_timezone: PropTypes.string,
      field_iframe_link: PropTypes.string,
      field_meeting_link: PropTypes.object,
      field_title_formatted: PropTypes.object,
      title: PropTypes.string,
      field_metatags: PropTypes.object,
      allWebformWebform: PropTypes.object,
      path: PropTypes.shape({
        alias: PropTypes.string,
      }),
      field_automatic_breadcrumbs: PropTypes.bool,
      relationships: PropTypes.shape({
        field_image_header: PropTypes.object,
        field_content: PropTypes.array,
        field_breadcrumbs: PropTypes.array,
      }),
    }),
  }),
};

export default EventTemplate;

export const query = graphql`
  query($drupal_id: String!) {
    nodeEventPage(drupal_id: { eq: $drupal_id }) {
      ...NodeEventPage
    }
  }
`;
